import * as braze from "@braze/web-sdk";
import startOfDay from "date-fns/startOfDay";
import React, { useContext, useEffect, useState } from "react";

import { useCustomRates } from "src/components/context/CustomRatesContext";
import TeamContext from "src/components/context/TeamContext";

import Button from "src/components/elements/Button";
import { FlashTypes } from "src/components/elements/Flash";
import Loader from "src/components/elements/Loader";
import MiqMobMenu from "src/components/elements/MobMenu";
import Modal from "src/components/elements/Modal";
import Text from "src/components/elements/Text";

import QuickTips from "src/components/blocks/QuickTips";
import { default as CustomRatesComponent } from "src/components/blocks/custom-rates/CustomRates";
import RatesBadge from "src/components/blocks/custom-rates/RatesBadge";
import RatesList from "src/components/blocks/custom-rates/RatesList";

import PageLayout from "src/components/PageLayout";

import useFlash from "src/hooks/useFlash";
import { DUNNING_STATUS, useTeamsCTA } from "src/hooks/useTeamCTA";

import { isTeamsProSubscription } from "src/models/team-subscription";

import { removeRates } from "src/services/teams";
import {
  customRatesEffectiveDateTypes,
  customRatesSelectionTypes,
  customRatesUpdateTypes,
  trackTeamsCustomRatesUpdateCompleted,
  trackTeamsCustomRatesUpdateStarted,
} from "src/services/tracking";
import { COUNTRIES, ratesInMilesToKm } from "src/services/utils";

import CustomRatesUpgrade from "./CustomRatesUpgrade";

const MODAL_ADDITIONAL_SIZE = {
  [COUNTRIES.US]: 300,
  [COUNTRIES.GB]: 100,
  [COUNTRIES.CA]: 100,
};

export default CustomRates;

function CustomRates() {
  const { team } = useContext(TeamContext);
  const [isRevertingTo, setRevertingTo] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isModalRemoveOpen, setModalRemoveOpen] = useState(false);
  const [isModalRevertOpen, setModalRevertOpen] = useState(false);
  const [rates, setRates] = useState(null);
  const [flash, Flash] = useFlash();
  const { country } = team;

  const {
    getCustomRates,
    isFetchingCustomRates,
    resetCustomRates,
    revertToDefaultRates,
    isRevertingCustomRates,
    isCurrentSameAsDefault,
  } = useCustomRates();

  const { checkAndHandleDunning } = useTeamsCTA();

  useEffect(() => {
    braze.logCustomEvent("teams_dash_custom_rates_view");
    getCustomRates();

    return () => resetCustomRates();
  }, []);

  const subscription =
    team?.subscription?.plan || team?.pendingSubscription?.plan;

  const onPlanUpgradeCallback = () => {
    flash(
      <Text>
        You have upgraded to <Text bold>Teams Pro</Text>!
      </Text>
    );
  };

  const handleCustomRatesSaved = () => {
    flash(
      <Text>
        {rates && !isRevertingTo
          ? "Your upcoming custom rate is updated"
          : "Your custom rate is updated"}
      </Text>
    );
    setModalOpen(false);
    setRevertingTo(false);
    getCustomRates();
  };

  const handleEdit = (rates) => {
    const dunningStatus = checkAndHandleDunning();

    if (dunningStatus === DUNNING_STATUS.EXPIRED) return;

    setRates(rates.clone());
    setModalOpen(true);
  };

  const handleRemove = async (rates) => {
    const dunningStatus = checkAndHandleDunning();

    if (dunningStatus === DUNNING_STATUS.EXPIRED) return;

    setRates(rates);
    setModalRemoveOpen(true);
  };

  const handleConfirmRemove = async (rates) => {
    try {
      setModalRemoveOpen(false);
      await removeRates(team.id, rates.parseId);
      trackTeamsCustomRatesUpdateCompleted({
        orgId: team.orgId,
        orgGroupId: team.orgGroupId,
        updateType: customRatesUpdateTypes.REMOVE,
        selection: customRatesSelectionTypes.UPCOMING,
      });
      flash(<Text>Your custom rate is removed</Text>);
    } catch (e) {
      flash(
        <Text>Error while trying to remove this rate, please try again.</Text>,
        { type: FlashTypes.ERROR }
      );
      console.error(e);
    }
    getCustomRates();
  };

  const handleRevert = (rates) => {
    const dunningStatus = checkAndHandleDunning();

    if (dunningStatus === DUNNING_STATUS.EXPIRED) return;

    setRevertingTo(true);
    const clone = rates.clone();
    clone.effectiveDate = startOfDay(new Date());
    // ignoring parse_id so it will be created as a new rate
    delete clone.data.parse_id;
    setRates(clone);
    setModalOpen(true);
  };

  const handleRevertToDefaultRates = async () => {
    await revertToDefaultRates();

    trackTeamsCustomRatesUpdateCompleted({
      orgId: team.orgId,
      orgGroupId: team.orgGroupId,
      updateType: customRatesUpdateTypes.REVERT_TO_DEFAULT,
      effectiveDate: customRatesEffectiveDateTypes.IMMEDIATE,
    });

    setModalRevertOpen(false);

    flash(<Text>Your team's rate is now the default rate</Text>);

    getCustomRates();
  };

  const handleCloseModal = () => {
    setRevertingTo(false);
    setModalOpen(false);
  };

  const changeCustomRate = () => {
    const dunningStatus = checkAndHandleDunning();

    if (dunningStatus === DUNNING_STATUS.EXPIRED) return;

    trackTeamsCustomRatesUpdateStarted({
      updateType: customRatesUpdateTypes.CHANGE,
      selection: customRatesSelectionTypes.CURRENT,
    });

    setRates(null);
    setModalOpen(true);
  };

  const openRevertToDefaultRatesModal = () => {
    const dunningStatus = checkAndHandleDunning();

    if (dunningStatus === DUNNING_STATUS.EXPIRED) return;

    trackTeamsCustomRatesUpdateStarted({
      updateType: customRatesUpdateTypes.REVERT_TO_DEFAULT,
    });

    setModalRevertOpen(true);
  };

  const renderCurrentRate = (rates) => {
    if (!rates) return;
    if (country === COUNTRIES.US) {
      const value = rates.value;
      const effectiveDateText = rates.effectiveDateText;
      const status = rates.status;
      return (
        <div className="flex justify-between">
          <div className="flex">
            <div className="p-2">
              <RatesBadge status={status} />
            </div>
            <div className="p-2">
              <Text bold>${value}/mi</Text>
            </div>
          </div>
          <div className="p-2">
            <Text>{effectiveDateText}</Text>
          </div>
        </div>
      );
    }
    if (country === COUNTRIES.GB) {
      const {
        car,
        moto,
        bicycle,
        car2,
        moto2,
        bicycle2,
        status,
        effectiveDateText,
      } = rates;
      return (
        <div className="flex justify-between">
          <div className="p-2 self-center">
            <RatesBadge status={status} />
          </div>
          <div className="p-2">
            <div className="flex flex-col gap-y-2.5">
              <Text bold>Cars and Vans</Text>
              <Text bold>Motorcycles</Text>
              <Text bold>Bikes</Text>
            </div>
          </div>
          <div className="p-2">
            <div className="flex flex-col gap-y-2.5">
              <Text bold>£{car}/mi</Text>
              <Text bold>£{moto}/mi</Text>
              <Text bold>£{bicycle}/mi</Text>
            </div>
          </div>
          <div className="p-2">
            <div className="flex flex-col gap-y-2.5">
              <Text bold>£{car2}/mi</Text>
              <Text bold>£{moto2}/mi</Text>
              <Text bold>£{bicycle2}/mi</Text>
            </div>
          </div>
          <div className="p-2 self-center">
            <Text>{effectiveDateText}</Text>
          </div>
        </div>
      );
    }
    if (country === COUNTRIES.CA) {
      const { value, value2, status, effectiveDateText } = rates;

      const valueInKm = ratesInMilesToKm(value)?.toFixed(2);
      const value2InKm = ratesInMilesToKm(value2)?.toFixed(2);

      return (
        <div className="flex justify-between">
          <div className="flex">
            <div className="p-2 self-center">
              <RatesBadge status={status} />
            </div>
            <div className="p-2">
              <div className="flex flex-col gap-y-2.5">
                <Text>First 5K Km</Text>
                <Text bold>${valueInKm}/km</Text>
              </div>
            </div>
            <div className="p-2">
              <div className="flex flex-col gap-y-2.5">
                <Text>After 5K Km</Text>
                <Text bold>${value2InKm}/km</Text>
              </div>
            </div>
          </div>
          <div className="p-2 self-center">
            <Text>{effectiveDateText}</Text>
          </div>
        </div>
      );
    }
  };

  return isTeamsProSubscription(subscription) ? (
    <PageLayout className="page-custom-rates">
      <PageLayout.Main>
        {isFetchingCustomRates ? (
          <Loader
            className="p-8"
            message="Processing... This may take a few minutes depending on the number of custom rates."
            timeout={10000}
          />
        ) : (
          <>
            <div className="page-header relative">
              <div className="title-row flex items-center justify-between pb-2">
                <div className="flex items-center">
                  <MiqMobMenu />
                  <h3>Custom Rates</h3>
                </div>
                <div className="flex gap-2">
                  {!isCurrentSameAsDefault && (
                    <Button
                      ghost
                      color="black-op50"
                      className="font-medium"
                      icon="restore"
                      onClick={openRevertToDefaultRatesModal}
                    >
                      <span>Revert to default</span>
                    </Button>
                  )}
                  <Button
                    icon="edit"
                    className="btn-manage-sub"
                    onClick={changeCustomRate}
                  >
                    <span>Change Rate</span>
                  </Button>
                </div>
              </div>
              <div className="w-9/12">
                <Text custom className="text-15" color="black/70">
                  You can use the default rate (based on your country's tax
                  authority) or set a custom rate. Note that your Team's drivers
                  cannot change the rate themselves.
                </Text>
              </div>
            </div>
            <div className="flex-grow">
              <RatesList
                onEdit={handleEdit}
                onRemove={handleRemove}
                onRevert={handleRevert}
              />
              <div className="flex gap-2 py-5 px-8">
                <Button
                  icon="edit"
                  className="btn-manage-sub"
                  onClick={changeCustomRate}
                >
                  <span>Change Rate</span>
                </Button>
                {!isCurrentSameAsDefault && (
                  <Button
                    ghost
                    color="black-op50"
                    className="font-medium"
                    icon="restore"
                    onClick={openRevertToDefaultRatesModal}
                  >
                    <span>Revert to default</span>
                  </Button>
                )}
              </div>
            </div>
          </>
        )}
      </PageLayout.Main>
      <PageLayout.Sidebar>
        <QuickTips showCustomRate />
      </PageLayout.Sidebar>
      {isModalOpen && (
        <Modal
          className={`miq-modal custom-rates-modal custom-rates-modal-${country}`}
          onClose={handleCloseModal}
          additionalContentSize={MODAL_ADDITIONAL_SIZE[country]}
        >
          <>
            <CustomRatesComponent
              rates={rates}
              isRevertingTo={isRevertingTo}
              onSave={handleCustomRatesSaved}
              onCancel={handleCloseModal}
            />
          </>
        </Modal>
      )}
      {isModalRemoveOpen && (
        <Modal
          className={`miq-modal custom-rates-remove custom-rates-remove-${country}`}
          onClose={() => setModalRemoveOpen(false)}
        >
          <h4 className="mt-0 mb-5 text-center">Remove this rate?</h4>
          <div className="p-3 border border-border-1 rounded">
            {renderCurrentRate(rates)}
          </div>
          <div className="pt-5 flex space-x-2 justify-end">
            <Button secondary onClick={() => setModalRemoveOpen(false)}>
              <span className="font-bold">Cancel</span>
            </Button>
            <Button destructive onClick={() => handleConfirmRemove(rates)}>
              <span className="font-bold">Remove</span>
            </Button>
          </div>
        </Modal>
      )}
      {isModalRevertOpen && (
        <Modal
          className="miq-modal custom-rates-revert-to-default"
          onClose={() => setModalRevertOpen(false)}
        >
          <Text paragraph center custom className="text-30 font-bold">
            Confirm to reset
          </Text>
          <Text className="pt-5" paragraph center>
            You are about to reset your team's mileage rate back to the current
            IRS rate by default. Would you like to continue?
          </Text>
          <Button
            lg
            className="w-full mt-10 font-medium"
            onClick={handleRevertToDefaultRates}
            loading={isRevertingCustomRates}
            disabled={isRevertingCustomRates}
          >
            <span>Confirm and reset</span>
          </Button>
        </Modal>
      )}
      {Flash}
    </PageLayout>
  ) : (
    <CustomRatesUpgrade onPlanUpgradeCallback={onPlanUpgradeCallback} />
  );
}
