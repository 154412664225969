const { gql } = require("@apollo/client");

export const GET_ME = gql`
  query getMe {
    me {
      parse_id
      email
      first_name
      last_name
      enabled
      is_premium
      is_verified
      distinct_id
      created_at
    }
  }
`;

export const GET_USER_DATA = gql`
  query getUserData {
    userData {
      id
      mobileAppUsed
      hasRealDrivesTaken
      zendeskJWT
      showGetStartedPage
      distanceUnit
      ratesName
      country
      commonRoutesEnabled
      commonRoutesOptOut
      isAutomaticReportSubmissionEnabled
      defaultVehicle
      sendWeekly
      sendMonthly
      sendOther
      isPromoOptin
      isPremium
      subscriptionType
      createdAt
      purposes {
        id
        label
        category
        isCustom
        isHidden
      }
      vehicles {
        id
        model
        make
        isActive
        name
        type
        year
        notes
        odometer {
          year
          value
        }
      }
      namedLocations {
        name
        displayName
        latitude
        longitude
        namedLocationDetails {
          id
          isActive
        }
      }
    }
  }
`;

export const GET_USER_ALL_TIME_TOTALS = gql`
  query getUserAllTimeTotals {
    drivesSummary {
      distance
      value
      totals {
        all
      }
    }
  }
`;

export const GET_USER_MONTH_STATS = gql`
  query getUserMonthStats {
    userMonthStats {
      totalDrives
    }
  }
`;

export const GET_EMAIL_PREFERENCES = gql`
  query getEmailPreferences($userId: String!) {
    emailPreferences(userId: $userId) {
      email
      sendWeekly
      sendMonthly
      sendOther
      sendPromo
      sendWarnings
    }
  }
`;

export const GET_RATES = gql`
  query getRates {
    rates {
      year
      dataJSON
    }
  }
`;

export const GET_CONTACT_TYPES = gql`
  query getContactTypes {
    getContactTypes {
      label
    }
  }
`;

export const GET_CONTACTS = gql`
  query getContacts {
    getContacts {
      id
      firstName
      lastName
      email
      relationship
    }
  }
`;

export const GET_DEFAULT_RATE = gql`
  query DefaultRate($name: String!, $year: String) {
    defaultRate(name: $name, year: $year) {
      createdAt
      currency
      displayName
      lastUpdated
      name
      objectId
      source
      units
      updatedAt
      year
      values {
        business {
          default
          ge_3125
          vehicle {
            automobile
            automobile_slab_2
            motorcycle
            motorcycle_slab_2
            bicycle
            bicycle_slab_2
          }
        }
        personal {
          charity
          medical
          moving
        }
      }
    }
  }
`;

export const GET_DRIVES_TOTALS = gql`
  query getDrivesTotals(
    $startDate: String
    $endDate: String
    $purposes: [String]
    $vehicles: [String]
    $isFrequent: Boolean
    $isWorkHours: Boolean
    $isManual: Boolean
    $withoutNotes: Boolean
    $location: LocationFilter
    $search: SearchFilter
    $withRoundTripStop: Boolean
    $userMetric: Boolean
  ) {
    drivesSummary(
      startDate: $startDate
      endDate: $endDate
      purposes: $purposes
      vehicles: $vehicles
      isFrequent: $isFrequent
      isWorkHours: $isWorkHours
      withoutNotes: $withoutNotes
      isManual: $isManual
      location: $location
      search: $search
      withRoundTripStop: $withRoundTripStop
      userMetric: $userMetric
    ) {
      totals {
        all
        personal
        business
        unclassified
      }
    }
  }
`;

export const GET_DRIVES_SUMMARY_QUERY = gql`
  query getDrivesSummary(
    $startDate: String
    $endDate: String
    $userMetric: Boolean
  ) {
    drivesSummary(
      startDate: $startDate
      endDate: $endDate
      userMetric: $userMetric
    ) {
      distance
      personalValue
      businessValue
      unclassifiedValue
    }
  }
`;

export const CONFIRM_PASSWORD = gql`
  query confirmPassword($password: String, $username: String) {
    confirmPassword(password: $password, username: $username) {
      is_valid_password
      code
    }
  }
`;

export const SEND_RESET_PASSWORD_EMAIL = gql`
  query sendResetPasswordEmail($username: String!) {
    sendResetPasswordEmail(username: $username) {
      code
    }
  }
`;

export const GET_DRIVES_QUERY = gql`
  query getUserDrives(
    $limit: Int
    $offset: Int
    $startDate: String
    $endDate: String
    $categories: [Int]
    $purposes: [String]
    $vehicles: [String]
    $isFrequent: Boolean
    $isWorkHours: Boolean
    $withoutNotes: Boolean
    $isManual: Boolean
    $isReport: Boolean
    $location: LocationFilter
    $search: SearchFilter
    $withRoutes: Boolean
    $withRoundTripStop: Boolean
    $userMetric: Boolean
  ) {
    drives(
      limit: $limit
      offset: $offset
      startDate: $startDate
      endDate: $endDate
      categories: $categories
      isReport: $isReport
      purposes: $purposes
      vehicles: $vehicles
      isFrequent: $isFrequent
      isWorkHours: $isWorkHours
      withoutNotes: $withoutNotes
      isManual: $isManual
      location: $location
      search: $search
      withRoutes: $withRoutes
      withRoundTripStop: $withRoundTripStop
      userMetric: $userMetric
    ) {
      id
      secondId
      purpose {
        id
        category
      }
      distance
      googleDistance
      value
      parkingFees
      tollFees
      notes
      potentialBusinessValue {
        default
      }
      potentialPersonalValue {
        charity
        medical
        moving
      }
      startLocation {
        latitude
        longitude
        name
        displayName
        fullAddress
        isNamed
        namedLocationDetails {
          id
          isActive
        }
      }
      startDate
      endLocation {
        latitude
        longitude
        name
        displayName
        fullAddress
        isNamed
        namedLocationDetails {
          id
          isActive
        }
      }
      endDate
      stopLocation {
        latitude
        longitude
        name
        displayName
        fullAddress
        isNamed
        namedLocationDetails {
          id
          isActive
        }
        stopDuration
      }
      stopDate
      isAutoClassified
      isRoundTrip
      isRoundTripStop
      isJoinedDrive
      isAutoClassifiedWorkHours
      isAutoClassifiedFrequentDrives
      isAutoClassifiedFrequentDrivesOverriden
      isReported
      timeZoneDiffInHours
      roundTripNumber
      routeId
      routeShouldAutoClassify
      routeClassificationsToPrompt
      routeClassificationStreak
      routeUnclassifiedDriveCount
      state
      vehicleId
      vehicleType
      map {
        startEndUrl
        startUrl
        endUrl
      }
      routeWaypoints
    }
  }
`;

export const GET_REPORT_SUBMISSIONS = gql`
  query getReportSubmissions {
    reportSubmissions {
      completed {
        driveCount
        unit
        expenseService
        driveValue
        startDate
        firstTimeZoneDiffInHours
        endDate
        lastTimeZoneDiffInHours
        parkingFees
        submissionDate
        submissionId
        tollFees
        reportName
        distance
        docName
        docObjectKey
        pdfObjectKey
      }
      processing {
        submissionEventKey
        reportName
        dateCreated
      }
      failed {
        submissionEventKey
        reportName
        dateCreated
        errorMessage
      }
    }
  }
`;

export const GET_REALM = gql`
  query Realm($username: String!) {
    realm(username: $username) {
      code
      realm
      userExists
      errorMessage
    }
  }
`;

export const LIST_INTEGRATIONS = gql`
  query Integrations {
    integrations {
      code
      error
      integrations {
        id
        type
        status
        teamId
        userParserId
      }
    }
  }
`;

export const GET_REGION = gql`
  query GetRegion {
    region
  }
`;

export const GET_WORK_HOURS = gql`
  query getWorkHours {
    workHours {
      processRule
      processRuleOutsideHours
      shifts
    }
  }
`;

export const GET_INTEGRATION_IDENTITY = gql`
  query GetIntegrationIdentity($integrationType: String!) {
    integrationIdentity(integrationType: $integrationType) {
      code
      error
      identity {
        integrationType
        integrationId
        identity
        isManuallySet
      }
    }
  }
`;

export const CREATE_DRIVER_CHECKOUT_SESSION = gql`
  query CreateDriverCheckoutSession(
    $promoCode: String
    $subscriptionType: String
    $stripePriceID: ID
    $successUrl: String
    $cancelUrl: String
    $ga4ClientId: String
  ) {
    createDriverCheckoutSession(
      subscriptionType: $subscriptionType
      stripePriceID: $stripePriceID
      promoCode: $promoCode
      successUrl: $successUrl
      cancelUrl: $cancelUrl
      ga4ClientId: $ga4ClientId
    ) {
      url
    }
  }
`;

export const CREATE_STRIPE_CUSTOMER_PORTAL_SESSION = gql`
  query CreateStripeCustomerPortalSession {
    createStripeCustomerPortalSession {
      url
    }
  }
`;

export const GET_USER_SUBSCRIPTION_DATA = gql`
  query GetSubscriptionData {
    getSubscriptionData {
      paymentService
      status
      type
      amount
      currentAmount
      nextPaymentDate
      endDate
    }
  }
`;

export const GET_NOTIFICATIONS = gql`
  query GetNotifications {
    getNotifications {
      code
      email
      status
      is_driver
      is_admin
      invite_link
      invited_at
      accepted_at
      team_name
      is_team_subscription_expired
      admin {
        id
        email
        is_owner
      }
    }
  }
`;

export const GET_CONCUR_REPORTS = gql`
  query GetConcurReports($concurUsername: String!) {
    getConcurReports(concurUsername: $concurUsername) {
      code
      error
      reports {
        id
        name
      }
    }
  }
`;

export const GET_PARTNER_CLIENT_NAME = gql`
  query GetPartnerClientName($clientId: String!, $redirectUrl: String!) {
    getPartnerClientName(clientId: $clientId, redirectUrl: $redirectUrl) {
      code
      error
      clientName
    }
  }
`;

export const SIGNED_MAP_URL = gql`
  query Query(
    $withRoutes: Boolean
    $driveId: String
    $driverId: String
    $endLocation: MapSignedLocation
    $startLocation: MapSignedLocation
    $height: Int
    $width: Int
  ) {
    signedMapUrl(
      withRoutes: $withRoutes
      driveId: $driveId
      endLocation: $endLocation
      startLocation: $startLocation
      driverId: $driverId
      height: $height
      width: $width
    ) {
      startEndUrl
      startUrl
      endUrl
      routeWaypoints
    }
  }
`;

export const GET_COUNTRY_CODE = gql`
  query Query {
    countryCode
  }
`;

export const SEARCH_SUGGESTIONS = gql`
  query SearchSuggestions($query: String!) {
    searchSuggestions(query: $query) {
      locations {
        isNamed
        locationId
        name
        city
        hood
        state
        country
      }
    }
  }
`;

export const GET_ZENDESK_JWT = gql`
  query zendeskJWT {
    zendeskJWT
  }
`;

export const GET_DRIVE_FEEDBACK = gql`
  query getDriveFeedback($id: String) {
    driveFeedback(id: $id)
  }
`;

export const GET_AUTOMATIC_REPORT_SUBMISSION_PREFERENCE = gql`
  query GetAutomaticReportSubmissionPreference {
    getAutomaticReportSubmissionPreference {
      code
      error
      isAutomaticReportSubmissionEnabled
      automaticReportSubmissionRecurrence
    }
  }
`;

export const GET_EXPERIMENTS_CONTEXT = gql`
  query GetExperimentsContext {
    experimentsContextData {
      country
      email
      isPremium
    }
  }
`;

export const GET_SUBSCRIPTION_PRICE = gql`
  query GetSubscriptionPrice(
    $origin: String
    $promoCode: String
    $contextKey: String
    $country: String
  ) {
    getSubscriptionPrice(
      origin: $origin
      promoCode: $promoCode
      contextKey: $contextKey
      country: $country
    ) {
      code
      contextKey
      error
      evaluationReason {
        reason
        rule_id
        value
        variation
        in_experiment
      }
      price {
        standalonePlans {
          monthly {
            value
            currency
            stripe_price_id
            stripe_billing_portal_config_id
            prod_stripe_price_id
            prod_stripe_billing_portal_config_id
          }
          yearly {
            value
            currency
            stripe_price_id
            stripe_billing_portal_config_id
            prod_stripe_price_id
            prod_stripe_billing_portal_config_id
          }
        }
      }
    }
  }
`;
