import { useLazyQuery, useQuery } from "@apollo/client";
import { useContext } from "react";

import { UserDataContext } from "src/components/context/UserContext";

import Icon from "src/components/elements/Icon";
import Loader from "src/components/elements/Loader";
import Text from "src/components/elements/Text";

import {
  SUBSCRIPTION_PLANS_DATA_BY_COUNTRY,
  SUBSCRIPTION_TYPE,
} from "src/services/billing";
import { setPendingUserSubscription } from "src/services/storage";
import {
  Pages,
  UpgradeSource,
  trackExperimentViewed,
  trackUpgradeStarted,
} from "src/services/tracking";
import {
  COUNTRIES,
  COUNTRIES_DATA,
  formatCurrency,
  getGA4ClientId,
} from "src/services/utils";

import {
  CREATE_DRIVER_CHECKOUT_SESSION,
  GET_SUBSCRIPTION_PRICE,
  GET_USER_MONTH_STATS,
} from "src/graphql/queries";

import ImgCar from "public/assets/img/car-on-money.svg";

export default FreePlan;

const MAX_FREE_DRIVES_PER_MONTH = 40;

function FreePlan() {
  const { userData } = useContext(UserDataContext);

  const umsQuery = useQuery(GET_USER_MONTH_STATS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });

  const subscriptionPriceQuery = useQuery(GET_SUBSCRIPTION_PRICE, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    variables: {
      country: userData?.country?.toLowerCase?.(),
    },
    onCompleted: (data) => {
      if (data?.getSubscriptionPrice?.evaluationReason?.in_experiment) {
        trackExperimentViewed({
          experimentId: 29,
          experimentName: "Standalone Price Increase - New Users",
          variationId: data.getSubscriptionPrice?.evaluationReason?.variation,
          variationName: data.getSubscriptionPrice?.evaluationReason?.value,
        });
      }
    },
  });

  if (umsQuery.loading || subscriptionPriceQuery.loading) return <Loader />;

  const drivesLeft = Math.max(
    0,
    MAX_FREE_DRIVES_PER_MONTH - umsQuery.data.userMonthStats.totalDrives
  );

  const priceDetails = subscriptionPriceQuery?.data?.getSubscriptionPrice
    ?.code === 200 && {
    inExperiment:
      subscriptionPriceQuery?.data?.getSubscriptionPrice?.evaluationReason
        ?.in_experiment,
    [SUBSCRIPTION_TYPE.MONTHLY]:
      subscriptionPriceQuery?.data?.getSubscriptionPrice?.price?.standalonePlans
        ?.monthly,
    [SUBSCRIPTION_TYPE.ANNUAL]:
      subscriptionPriceQuery?.data?.getSubscriptionPrice?.price?.standalonePlans
        ?.yearly,
  };

  return (
    <div className="flex flex-col gap-[20px]">
      <Text bold>Your subscription</Text>
      <DrivesLeft drivesLeft={drivesLeft} />
      <Upgrade priceDetails={priceDetails} />
    </div>
  );
}

function DrivesLeft({ drivesLeft }) {
  const { userData } = useContext(UserDataContext);

  const label = `${
    drivesLeft > 0 && drivesLeft < 10 ? "Fewer than 10" : drivesLeft
  } drives left this month`;

  const { taxAuthority } = COUNTRIES_DATA[userData.country];

  return (
    <div className="border border-border-1 rounded p-[10px] pb-[20px]">
      <div
        className={`h-[40px] flex items-center px-3 ${
          drivesLeft > 0 ? "bg-black" : "bg-red"
        } rounded-10`}
      >
        <span className="scale-75">
          <Icon name="alert" color="white" />
        </span>
        <Text md color="white" className="ml-2">
          {label}
        </Text>
      </div>
      <div className="pl-3">
        <h6 className="mt-4">Limited plan (free)</h6>
        <Text paragraph color="black/70" className="mt-2">
          Your limited plan only tracks 40 free drives. Don't miss any drives!
          Upgrade to our Unlimited plan TODAY to stay {taxAuthority} compliant.
        </Text>
      </div>
    </div>
  );
}

export function Upgrade({ priceDetails }) {
  const { userData } = useContext(UserDataContext);

  // TODO: Remove country check once we can infer prices for non-US users from the new pricing API
  if (!priceDetails || userData?.country !== COUNTRIES.US) {
    priceDetails = {
      [SUBSCRIPTION_TYPE.MONTHLY]: {
        value:
          SUBSCRIPTION_PLANS_DATA_BY_COUNTRY[userData.country].prices[
            SUBSCRIPTION_TYPE.MONTHLY
          ],
        currency: userData.currency,
      },
      [SUBSCRIPTION_TYPE.ANNUAL]: {
        value:
          SUBSCRIPTION_PLANS_DATA_BY_COUNTRY[userData.country].prices[
            SUBSCRIPTION_TYPE.ANNUAL
          ],
        currency: userData.currency,
      },
    };
  }

  const [checkoutQueryFn, checkoutQueryState] = useLazyQuery(
    CREATE_DRIVER_CHECKOUT_SESSION,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
    }
  );

  const handlePickSubscription = async (type) => {
    if (checkoutQueryState.loading) return;

    const stripePriceKey =
      process.env.APP_ENV === "production"
        ? "prod_stripe_price_id"
        : "stripe_price_id";

    const ga4ClientId = await getGA4ClientId();
    checkoutQueryFn({
      variables: {
        subscriptionType: type,
        stripePriceID: priceDetails?.[type]?.[stripePriceKey],
        successUrl: window.location.origin,
        cancelUrl: window.location.origin,
        ga4ClientId,
      },
      onCompleted: (data) => {
        if (data?.createDriverCheckoutSession?.url) {
          setPendingUserSubscription({ isNew: true });
          trackUpgradeStarted({
            page: Pages.SUBSCRIPTION,
            src: UpgradeSource.SUBSCRIPTION_PAGE,
          });
          window.location.href = data.createDriverCheckoutSession.url;
          return;
        }
      },
    });
  };

  const annualPrice = parseFloat(priceDetails[SUBSCRIPTION_TYPE.ANNUAL].value);
  const monthlyPrice = parseFloat(
    priceDetails[SUBSCRIPTION_TYPE.MONTHLY].value
  );

  const savings = Math.round(100 * (1 - annualPrice / 12 / monthlyPrice));

  const annualPriceFmt = formatCurrency({
    value: annualPrice,
    currency: priceDetails[SUBSCRIPTION_TYPE.ANNUAL].currency,
  });
  const monthlyPriceFmt = formatCurrency({
    value: monthlyPrice,
    currency: priceDetails[SUBSCRIPTION_TYPE.MONTHLY].currency,
  });

  return (
    <div className="bg-orange-2 rounded p-5">
      <div className="flex flex-col gap-3 sm:gap-12 sm:items-center sm:flex-row">
        <div className="max-w-[300px]">
          <h6>Upgrade to an Unlimited plan</h6>
          <ul className="mt-[15px] flex flex-col gap-3">
            <li className="flex gap-[7px] items-start">
              <Icon name="autorenew" />
              <Text paragraph color="black/70" className="text-[13px]">
                <Text color="black" bold>
                  Works automatically:
                </Text>{" "}
                Say goodbye to the headache of manually logging every trip.
              </Text>
            </li>
            <li className="flex gap-[7px] items-start">
              <Icon name="clock-eight" />
              <Text paragraph color="black/70" className="text-[13px]">
                <Text color="black" bold>
                  Time savings:
                </Text>{" "}
                A simple swipe is all it takes, letting you focus on your
                business.
              </Text>
            </li>
            <li className="flex gap-[7px] items-start">
              <Icon name="dollar" />
              <Text paragraph color="black/70" className="text-[13px]">
                <Text color="black" bold>
                  Claim your full deduction:
                </Text>{" "}
                The average MileIQ user deducts $6,500 per year.
              </Text>
            </li>
          </ul>
        </div>
        <ImgCar className="self-center sm:self-auto" />
      </div>

      <div
        className={`flex flex-col sm:flex-row gap-3 mt-[30px] ${
          checkoutQueryState.loading ? "[&_button]:cursor-progress" : ""
        }`}
        data-testid="subscription-upgrade-plans"
      >
        <button
          onClick={() => handlePickSubscription(SUBSCRIPTION_TYPE.ANNUAL)}
          className="relative w-full flex items-center justify-between bg-yellow rounded p-5"
          id="cta_sa_unlimited_year"
        >
          <span className="flex flex-col gap-[4px]">
            <Text bold md>
              Annual
            </Text>
            <Text md>
              {annualPriceFmt} (Save {savings}%)
            </Text>
          </span>
          <span className="h-[35px] pl-[10px] pr-[5px] bg-white flex items-center rounded-10 gap-2">
            <Text bold md>
              Select
            </Text>
            <Icon name="arrow-right" />
          </span>
          <span className="absolute -top-[12px] left-[15px] bg-green h-[25px] flex items-center px-[10px] rounded-full">
            <Text md color="white">
              Best value
            </Text>
          </span>
        </button>
        <button
          onClick={() => handlePickSubscription(SUBSCRIPTION_TYPE.MONTHLY)}
          className="w-full flex items-center justify-between bg-white rounded p-5"
          id="cta_sa_unlimited_month"
        >
          <span className="flex flex-col gap-[4px]">
            <Text bold md>
              Monthly
            </Text>
            <Text md>{monthlyPriceFmt}/month</Text>
          </span>
          <span className="h-[35px] pl-[10px] pr-[5px] bg-white border-2 border-border-2 flex items-center rounded-10 gap-2">
            <Text bold md>
              Select
            </Text>
            <Icon name="arrow-right" />
          </span>
        </button>
      </div>
    </div>
  );
}
