import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import React from "react";
import { createRoot } from "react-dom/client";

import App from "src/components/App";

import { init as initErrorReporting } from "src/services/error-reporting";

// init services
const ErrorBoundary = initErrorReporting();

// add GTM
if (process.env.APP_ENV === "production") {
  const gtm = document.createElement("script");
  gtm.innerText = `
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${process.env.GA_GTM_KEY}');
  `.replace(/\n/g, "");
  document.head.appendChild(gtm);
}

// add OS-specific body class
const ua = navigator.userAgent;
switch (true) {
  case ua.includes("Mac"):
    document.body.classList.add("os-macos");
    break;
  case ua.includes("Windows"):
    document.body.classList.add("os-windows");
    break;
}

async function renderApp() {
  const root = createRoot(document.getElementById("root"));
  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.LAUNCHDARKLY_CLIENT_SIDE_ID,
    options: {
      evaluationReasons: true,
    },

    // TODO: consider using this approach to only load flags used by the app, instead of loading all
    // flags: {
    //   "miq-flag": "miq-flag-default-value",
    // }

    // TODO: consider using this approach to prevent flag keys from being converted to camelCase
    // reactOptions: { useCamelCaseFlagKeys: false },
  });

  root.render(
    <ErrorBoundary>
      <LDProvider>
        <App />
      </LDProvider>
    </ErrorBoundary>
  );
}

renderApp();
