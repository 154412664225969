import {
  TEAMS_SUBSCRIPTION_PLANS,
  TEAM_SUBSCRIPTION_TYPES,
  getStripeSubscriptionCode,
} from "src/models/team-subscription";

import http from "src/services/http";

import { COUNTRIES, getGA4ClientId } from "./utils";

const teamsApiUrl = process.env.TEAMS_API_URL;

export const PAYMENT_METHOD = {
  STRIPE: "STRIPE",
  GOOGLE: "GOOGLE",
  APPLE: "APPLE",
  APPLE_SANDBOX: "APPLE-SANDBOX",
};

export const PAYMENT_METHOD_DATA = {
  [PAYMENT_METHOD.STRIPE]: {
    label: "Stripe",
  },
  [PAYMENT_METHOD.GOOGLE]: {
    label: "Google Play Store",
    editPaymentInfoUrl: "https://play.google.com/store/account/subscriptions",
  },
  [PAYMENT_METHOD.APPLE]: {
    label: "App Store",
    editPaymentInfoUrl: "https://apps.apple.com/account/subscriptions",
  },
  [PAYMENT_METHOD.APPLE_SANDBOX]: {
    label: "App Store(Sandbox)",
    editPaymentInfoUrl: "https://apps.apple.com/account/subscriptions",
  },
};

export const SUBSCRIPTION_TYPE = {
  MONTHLY: "MONTHLY",
  ANNUAL: "ANNUAL",
};

export const SUBSCRIPTION_TYPE_DATA = {
  [SUBSCRIPTION_TYPE.MONTHLY]: { label: "Monthly" },
  [SUBSCRIPTION_TYPE.ANNUAL]: { label: "Annual" },
};

export const SUBSCRIPTION_STATUS = {
  ACTIVE: "ACTIVE",
  CANCELLED: "CANCELLED",
  EXPIRED: "EXPIRED",
};

export const SUBSCRIPTION_STATUS_DATA = {
  [SUBSCRIPTION_STATUS.ACTIVE]: { label: "Active" },
  [SUBSCRIPTION_STATUS.CANCELLED]: { label: "Cancelled" },
  [SUBSCRIPTION_STATUS.EXPIRED]: { label: "Expired" },
};

export const SUBSCRIPTION_PLANS_DATA_BY_COUNTRY = {
  [COUNTRIES.US]: {
    prices: {
      [SUBSCRIPTION_TYPE.MONTHLY]: 5.99,
      [SUBSCRIPTION_TYPE.ANNUAL]: 59.99,
    },
  },
  [COUNTRIES.CA]: {
    prices: {
      [SUBSCRIPTION_TYPE.MONTHLY]: 7.99,
      [SUBSCRIPTION_TYPE.ANNUAL]: 79.99,
    },
  },
  [COUNTRIES.GB]: {
    prices: {
      [SUBSCRIPTION_TYPE.MONTHLY]: 4.49,
      [SUBSCRIPTION_TYPE.ANNUAL]: 44.99,
    },
  },
};

export const getPriceByFlag = (prices, plan, type) => {
  const dict = {
    plan: {
      [TEAMS_SUBSCRIPTION_PLANS.TEAMS_LITE]: "lite",
      [TEAMS_SUBSCRIPTION_PLANS.TEAMS]: "standard",
    },
    type: {
      [TEAM_SUBSCRIPTION_TYPES.MONTH]: "monthly",
      [TEAM_SUBSCRIPTION_TYPES.YEAR]: "yearly",
    },
  };
  return prices?.[dict.plan[plan]]?.[dict.type[type]];
};

export async function createTeamsCheckoutSession(
  planCode,
  planType,
  quantity,
  returnUri = window.location.href,
  isFreeTrial = false,
  isReverseTrial = false,
  prices
) {
  let subTypeId = getStripeSubscriptionCode(planCode, planType);

  // if prices are provided from FF then find planCode and planType from stripePriceID
  // and use it to update the subscription instead of the one from the URL
  if (prices) {
    let stripePriceID;
    const isLiteOrStandard = [
      TEAMS_SUBSCRIPTION_PLANS.TEAMS_LITE,
      TEAMS_SUBSCRIPTION_PLANS.TEAMS,
    ].includes(planCode);
    if (isLiteOrStandard) {
      const price = getPriceByFlag(prices, planCode, planType);
      stripePriceID = price?.stripePriceID;
    }
    if (stripePriceID) {
      subTypeId = stripePriceID;
    }
  }

  const uri = new URL(
    `${teamsApiUrl}/checkout?subscription_type=${subTypeId}&quantity=${quantity}`
  );
  if (isReverseTrial === "override") {
    uri.searchParams.append("override", "true");
  } else if (isReverseTrial) {
    uri.searchParams.append("is_reverse_trial", "true");
  }

  if (returnUri) {
    const returnURL = new URL(returnUri);
    returnURL.searchParams.set("code", planCode);
    returnURL.searchParams.set("quantity", quantity);

    if (isFreeTrial) {
      returnURL.searchParams.set("free-trial", "true");
      uri.searchParams.append("free_trial", "true");
    }

    returnURL.searchParams.delete("checkout-status");
    returnURL.searchParams.set("checkout-status", "success");
    uri.searchParams.append("success_redirect", returnURL.toString());

    returnURL.searchParams.delete("checkout-status");
    returnURL.searchParams.set("checkout-status", "cancel");
    uri.searchParams.append("cancel_redirect", returnURL.toString());
  }

  const ga4ClientId = await getGA4ClientId();
  if (ga4ClientId) {
    uri.searchParams.append("ga4_client_id", ga4ClientId);
  }

  return http.get(uri.toString());
}

export function createTeamsPortalSession() {
  return http.get(`${teamsApiUrl}/portal`);
}

export function fetchInvoices(teamId) {
  return http.get(`${teamsApiUrl}/teams/${teamId}/invoices`);
}

// TODO: remove exporting this once we are done with the price migration
export function _updateSubscriptionType(type, preview = true) {
  return http.post(`${teamsApiUrl}/modify-subscription`, {
    subscription_type: type,
    preview,
  });
}

export function updateSubscriptionPreview(planCode, planType) {
  const subTypeId = getStripeSubscriptionCode(planCode, planType);
  return _updateSubscriptionType(subTypeId, true);
}

export function updateSubscription(planCode, planType) {
  const subTypeId = getStripeSubscriptionCode(planCode, planType);
  return _updateSubscriptionType(subTypeId, false);
}
